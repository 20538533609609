import { AppIcon, AppIconType } from 'common';
import cx from 'classnames';
import React, { FC } from 'react';
import { siteData } from '../../data/site-data';
import { MainContent } from '../layout/main-content';

import './make-an-appointment.scss';

interface Props {
  icon?: AppIconType;
  cls?: string;
}

export const SolutionsMakeAnAppointment: FC<Props> = ({ cls, icon }) => {
  /**
   * DOM
   */
  return (
    <div className={cx('solutions-make-an-appt-container flex justify-center', cls)}>
      <MainContent width="max-w-3xl">
        <div className="flex flex-col items-center py-32">
          <AppIcon
            bg=""
            icon={icon || (siteData.solutionsPage.sectionCard.imageName as AppIconType)}
            size={64}
          />
          <p
            className={
              'ff-secondary text-center text-white whitespace-pre-line text-3xl py-6 lg:py-10'
            }
          >
            {siteData.solutionsPage.sectionCard.title}
          </p>
          {siteData.solutionsPage.sectionCard.description}
        </div>
      </MainContent>
    </div>
  );
};
