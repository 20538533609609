import React from 'react';
import Fade from 'react-reveal/Fade';
import { MainContent } from '../layout/main-content';
import { siteData } from '../../data/site-data';
import { AppIcon, AppIconType } from 'common';

export const SolutionsOurSolutions = () => {
  /**
   * DOM
   */
  return (
    <div className="bg-accent-light py-20">
      <MainContent>
        <p className="ff-secondary text-center text-primary typo-h3 mb-8">
          Our solutions
        </p>
        <div className="flex flex-col md:flex-row justify-evenly">
          {siteData.solutionsPage.sectionSolutions.map(item => {
            return (
              <Fade key={item.title}>
                <div className="flex flex-col items-center mx-6 flex-1 my-10">
                  <AppIcon icon={item.imageName as AppIconType} size={64} />
                  <p className="typo-20 font-semibold my-4">{item.title}</p>
                  <p className="text-grey-darker text-center typo-b2">
                    {item.description}
                  </p>
                </div>
              </Fade>
            );
          })}
        </div>
      </MainContent>
    </div>
  );
};
