import React, { FC } from 'react';
import QuoteBlueIcon from 'common/dist/assets/icons/double-quote-blue.svg';
import { SectionImage, SectionImageType } from '../section-image';
import { MainContent } from '../../layout/main-content';

import './index.scss';

interface Props {
  imageName?: SectionImageType;
  title: string;
  subTitle: string;
}

export const SectionCard: FC<Props> = ({ imageName, title, subTitle }) => {
  /**
   * DOM
   */
  return (
    <div className="section-card flex justify-center">
      <MainContent width="max-w-4xl">
        <div className="flex flex-col items-center py-20 md:py-32">
          {imageName ? (
            <SectionImage name={imageName} />
          ) : (
            <div className="w-12">
              <QuoteBlueIcon />
            </div>
          )}
          <p
            className={
              'ff-secondary text-center text-primary whitespace-pre-line typo-24 py-10 lg:py-14'
            }
          >
            {title}
          </p>
          <p className="font-bold text-center">{subTitle}</p>
        </div>
      </MainContent>
    </div>
  );
};
